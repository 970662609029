import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <>
      <Helmet>
        <meta name='robots' content='nofollow' />
        <meta name='googlebot' content='noindex' />
      </Helmet>
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
      </>
    )
  } else {
    header = (
      <>
        <Helmet>
          <meta name='robots' content='nofollow' />
          <meta name='googlebot' content='noindex' />
        </Helmet>
        <h1 className="main-heading">
          <Link to="/">{title}</Link>
        </h1>
        <Link className="header-link-home" to="/">
          {title}
        </Link>
      </>

    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
